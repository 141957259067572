import * as React from "react"
import { graphql } from "gatsby"
import {Helmet} from "react-helmet";

const introStyles = {
  maxWidth: "30rem",
}

// markup
const IndexPage = ({data}) => {

  const front = data.markdownRemark.frontmatter;

  return (
    <main className="flex items-center justify-center w-full h-full">
      <Helmet>
        <title>{front.head.title}</title>
        <meta name="description" content={front.head.description} />
      </Helmet>
      <div style={introStyles} className="text-left p-4">
        <h1 className="uppercase font-light text-5xl tracking-widest md:text-7xl mb-4">
          {front.title}
        </h1>
        <h2 className="text-xl mb-8">
          {front.tagline}
        </h2>
        <div className="text-left text-gray-700 mx-auto"
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
        </div>
        <p className="mt-20 uppercase text-xl text-right text-gray-500 tracking-widest">
          Coming soon
        </p>
      </div>
    </main>
  )
}

export default IndexPage

export const comingSoonQuery = graphql`
query MyQuery {
  markdownRemark(fileAbsolutePath: {regex: "/coming-soon.md/"}) {
    html
    frontmatter {
      title
      tagline
      head {
        title
        description
      }
    }
  }
}
`;